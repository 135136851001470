import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <footer className="footer1">
          <div className="footer-area-bottom">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 text-center">
                  <div className="copyright">
                    <p className="mb-0">© TronDollar 2022. All Rights Reserved</p>
                  </div>
                </div>                    
              </div>
            </div>
          </div>
        </footer>
      );
    }
  }

  export default Footer;