import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter,Link} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CircularProgress from '@material-ui/core/CircularProgress';

import TronWeb from 'tronweb';
import { utils as Utils, SunMoon } from '../../utils';
import {tronApi,communityDetailsApi,withdrawApi,missingTransactionApi,networkSettingApi,
  activeBotApi, tradeHistoryApi, tradeHistoryPairApi} from '../../utils';
import ReCAPTCHA from "react-google-recaptcha";
const defaultAmountArr = [1000,2000];

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:false,
        isConnected: false, 
        tronWeb: {
          installed: false,
          loggedIn: false
        },
        contractInfo: {
          contractAddress:'-',
          contractAddressLinkto: null,
          ReferredByContent:null,
          ReferredBy: null,
        },
        userInfo:{
          YourAddress: null,
          YourAddressfull:null,
          TotalWithdrawn:0,
          TotalDeposits:0,
          checkpoint:0
        },
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        amountTrx:0,
        globalInfo:{
          user_count: 0,
          withdraw_amount:0,
          investment_amount:0
        },
        userDetails:{
          wallet_balance:0,
          upline_income:0,
          downline_income:0,
          binaryIncome:0,
          sponsorIncome:0,
          totalIncome:0,
          user_withdraw:0,
          user_investment:0,
          userReferredBy:'-',
          userReferredByFull:'-',
          maxUplineIncomeLevel:0,
          maxDownlineIncomeLevel:0,
          maxDownlineIncomeLevel:0,
          upline_percentage:0,
          downline_percentage:0,

          instantIncome:0,
          teamIncome:0,
          lifetimeIncome:0,
          roiIncome:0
        },        
        sponsorDetails:[],
        loadingWithdrawl:false,
        withdrawlAmount:0,
        captchaValueWithdrawal:null,
        loadingMissingTrsn:false,
        transaction_hash:'',
        captchaValueMT:null,
        loadingNSetting:false,
        default_leg:'L',
        getValidAddress:null,
        inputValidAddress:null,
        editAddress:true,

        activeBots:null,
        activeBots_setting:null,
        tradeHistory:null,
        simUrl:'',
        modalIsOpen:false,
        modalIsOpenSim:false,
        selected_pair:null,
        tradeHistoryPair:null,
        min_withdraw:5
      }
    }

    async componentDidMount(){
      await new Promise(resolve => {
      const tronWebState = {
          installed: !!window.tronWeb,
          loggedIn: window.tronWeb && window.tronWeb.ready
      };
  
      if(tronWebState.installed) {
          this.setState({
              tronWeb:
              tronWebState
          });
  
          return resolve();
      }
  
      let tries = 0;
  
      const timer = setInterval(() => {
          if(tries >= 1000) {
              const TRONGRID_API = 'https://api.shasta.trongrid.io';
  
              window.tronWeb = new TronWeb(
                  TRONGRID_API,
                  TRONGRID_API,
                  TRONGRID_API
              );
  
              this.setState({
                  tronWeb: {
                      installed: false,
                      loggedIn: false
                  }
              });
  
              clearInterval(timer);
              return resolve();
          }
  
          tronWebState.installed = !!window.tronWeb;
          tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
  
          if(!tronWebState.installed)
              return tries++;
  
          this.setState({
              tronWeb: tronWebState
          });
  
          resolve();
      }, 100);
      });
  
     
  
      if(!this.state.tronWeb.loggedIn) {
          window.tronWeb.on('addressChanged', () => {
              if(this.state.tronWeb.loggedIn)
                  return;
  
              this.setState({
                  tronWeb: {
                      installed: true,
                      loggedIn: true
                  }
              });
          });
      }
  
      setInterval(()=>{
        this.fetchData();
      },3000);

      //this.fetchSunMoonData();
      
      this.communityDetailsApi(1);
      this.activeBotApi();
      this.tradeHistoryApi();
  
      window.tronWeb.on('addressChanged', () => {
          if(this.state.tronWeb.loggedIn)
          this.fetchData();
          this.communityDetailsApi(1);
          this.activeBotApi();
          this.tradeHistoryApi();
      });
    }
  
    fetchData= async()=>{
      await Utils.setTronWeb(window.tronWeb);
  
      const contactInfo = await Utils.contract.contractInfo().call();
      const contractBalance = await window.tronWeb.trx.getBalance(Utils.address);
      const contractAddress = Utils.address;
  
      this.setState(({contractInfo})=>
        ({contractInfo : {...contractInfo, 
        contractAddress:contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length),
        contractAddressLinkto:contractAddress,
        contractBalance: window.tronWeb.fromSun(contractBalance),
        }})
      );
  
      const users = await Utils.contract.users(window.tronWeb.defaultAddress.base58).call();
      const referrer = window.tronWeb.address.fromHex(users.referrer);
      const checkpoint = users.checkpoint;
      const checkpointVal = window.tronWeb.toDecimal(checkpoint._hex);
      const getUserTotalWithdrawn = await Utils.contract.getUserTotalWithdrawn(window.tronWeb.defaultAddress.base58).call();
      const TotalWithdrawn = window.tronWeb.toDecimal(getUserTotalWithdrawn._hex);
      
      const getUserTotalDeposits = await Utils.contract.getUserTotalDeposits(window.tronWeb.defaultAddress.base58).call();
      const TotalDeposits = window.tronWeb.toDecimal(getUserTotalDeposits._hex);
      
      
      this.setState(({userInfo})=>
        ({userInfo : {...userInfo, 
          YourAddress: window.tronWeb.defaultAddress.base58.slice(0, 10) + '.....' + window.tronWeb.defaultAddress.base58.slice(window.tronWeb.defaultAddress.base58.length-5, window.tronWeb.defaultAddress.base58.length),
          YourAddressfull:window.tronWeb.defaultAddress.base58,
          TotalWithdrawn:window.tronWeb.fromSun(TotalWithdrawn),
          TotalDeposits:window.tronWeb.fromSun(TotalDeposits),
          checkpoint:checkpointVal
        }})
      );
  
      this.setState(({contractInfo})=>
        ({contractInfo : {...contractInfo, 
          ReferredByContent: referrer.slice(0, 5) + '...' +referrer.slice(referrer.length-5, referrer.length),
          ReferredBy: (referrer != 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') ? referrer : null
        }})
      );    
    }
     
    fetchSunMoonData= async()=>{
      await SunMoon.setTronWeb(window.tronWeb);
      let getValidAddress = await SunMoon.contract.getValidAddress(window.tronWeb.defaultAddress.base58).call();
      let editAddress = true;
      if(getValidAddress){
        editAddress = false;
      }
      this.setState({getValidAddress, editAddress});
    }

    communityDetailsApi = async(refresh=1)=>{
      if(!window.tronWeb.defaultAddress.base58){
        return false;
      }
      const data = await communityDetailsApi(window.tronWeb.defaultAddress.base58,refresh);
      // const data = await communityDetailsApi('TTgH6SS8n1EBmH9AiT6cu8d1QvENhF7GXQ ',refresh);
      // console.log('communityDetailsApi',data);
      if(data.status){
        this.setState(({globalInfo})=>
          ({globalInfo : {...globalInfo, 
            user_count: data.data.systemUserCount,
            withdraw_amount:data.data.systemWithdraw,
            investment_amount:data.data.systemInvest
          }})
        );
        this.setState(({userDetails})=>
          ({userDetails : {...userDetails,
            wallet_balance:data.data.walletBalance,
            user_withdraw:data.data.userWithdraw,
            user_investment:data.data.userDeposit,
            userReferredByFull:data.data.userReferredBy?data.data.userReferredBy:null,
            userReferredBy:data.data.userReferredBy ? data.data.userReferredBy !='admin'? data.data.userReferredBy.slice(0, 5) + '...' +data.data.userReferredBy.slice(data.data.userReferredBy.length-5, data.data.userReferredBy.length):'admin':null,

            instantIncome:data.data.instantIncome,
            teamIncome:data.data.teamIncome,
            lifetimeIncome:data.data.lifetimeIncome,
            roiIncome:data.data.roiIncome
          }})
        );
        
        this.setState({default_leg:data.data.default_leg,loading:false});
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }  
  
    refreshApiData = async() => {
      this.setState({loading:true},()=>{
        this.communityDetailsApi(1);
        this.activeBotApi();
        this.tradeHistoryApi();
      });
    }
  
    doSubmit = async(e) => {
      let validateForm = true;
      let ReferredAddress = '';
  
      //console.log(this.state.sponsor_address,this.state.userInfo.YourAddressfull);
  
      if(this.state.sponsor_address == this.state.userInfo.YourAddressfull){     
  
        this.props.enqueueSnackbar("Sponsor Address should not same Deposit Address!",{ variant: 'error' })
  
        this.setState({sponsor_addressError:true});
        validateForm = false;
        return false;
      }
  
      if(!this.state.sponsor_address && !this.state.contractInfo.ReferredBy){
          this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
          this.setState({sponsor_addressError:true});
          validateForm = false;
          return false;
      }
      if(!this.state.amountTrx){
          this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
          this.setState({amountError:true});
          validateForm = false;
          return false;
      }
  
      if(this.state.amountTrx < defaultAmountArr[0]){
        this.props.enqueueSnackbar(`Min deposit ${defaultAmountArr[0]} TRX!`,{ variant: 'error' })
        this.setState({amountError:true});
        validateForm = false;
        return false;
      }    
  
      if(!validateForm){
          return false;
      }
  
      if(this.state.contractInfo.ReferredBy){
        ReferredAddress = this.state.contractInfo.ReferredBy
      }
  
      if(this.state.sponsor_address){
        ReferredAddress = this.state.sponsor_address
      }
  
      try {           
        const balance = await window.tronWeb.trx.getBalance(window.tronWeb.defaultAddress.base58);
        //console.log(validateForm, balance);
        if(balance > window.tronWeb.toSun(this.state.amountTrx)){
            this.setState({loading:true});
            const res= await Utils.contract.invest(ReferredAddress).send(
              {
                feeLimit:300000000,
                callValue:window.tronWeb.toSun(this.state.amountTrx)
              }
            );
            //console.log('After Submit...');
            //console.log(res);
            //await  HashPost(res);
            const data = await tronApi(res);
            if(data.status){
  
              this.setState({loading:false});
              this.props.enqueueSnackbar("Congratulation! Your Deposit made successfully! Within 5 minutes it will reflect on dashboard!",{ variant: 'success' })
  
            }else{
              this.setState({loading:false});
              this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
              //////////// transaction error ////////////
            }
  
        }else{
          this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' })                
        }    
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loading:false});
        //let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';
        let defaultError = err;
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = window.tronWeb.toUtf8(err.output.contractResult[0]);
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }
  
    doWithdrawl = async(e) => {
      if(!this.state.withdrawlAmount){
        this.props.enqueueSnackbar("Withdrawl Amount is required!",{ variant: 'error' });
        this.setState({withdrawlAmountError:true});
        return false;
      }
  
      if(this.state.withdrawlAmount < this.state.min_withdraw){
        this.props.enqueueSnackbar(`Min Withdrawal ${this.state.min_withdraw} TRX!`,{ variant: 'error' })
        this.setState({withdrawlAmountError:true});
        return false;
      }
  
      if(this.state.userDetails.wallet_balance < this.state.min_withdraw){
        this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' });
        return false;
      }

      // if(!this.state.captchaValueWithdrawal){
      //   this.props.enqueueSnackbar("Invalid Captcha!",{ variant: 'error' });
      //   return false;
      // }
  
      try {
        this.setState({loadingWithdrawl:true});
        const data = await withdrawApi(window.tronWeb.defaultAddress.base58,this.state.withdrawlAmount,this.state.captchaValueWithdrawal);
        //const data = await withdrawApi('TWB7vLgUPnbr4pyFU4Dk1nSWLSuFQAibYh',this.state.withdrawlAmount,this.state.captchaValueWithdrawal);
        //console.log('doWithdrawl',data);
        if(data.status){
          this.setState({withdrawlAmount:0, loadingWithdrawl:false});
          this.props.enqueueSnackbar("Congratulation! Your Withdrawl made successfully!",{ variant: 'success' })
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loadingWithdrawl:false});
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loadingWithdrawl:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }
  
    doMissingTrsn = async(e) => {
      if(!this.state.transaction_hash){
        this.props.enqueueSnackbar("Transaction Hash is required!",{ variant: 'error' });
        this.setState({transaction_hashError:true});
        return false;
      }

      // if(!this.state.captchaValueMT){
      //   this.props.enqueueSnackbar("Invalid Captcha!",{ variant: 'error' });
      //   return false;
      // }
  
      try {
        this.setState({loadingMissingTrsn:true, loading:true});
        const data = await missingTransactionApi(this.state.transaction_hash, this.state.captchaValueMT);
        //console.log('doMissingTrsn',data);
        if(data.status){
          this.setState({transaction_hash:'', loadingMissingTrsn:false, loading:false});
          this.props.enqueueSnackbar(data.message,{ variant: 'success' })
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loadingMissingTrsn:false, loading:false});
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loadingMissingTrsn:false, loading:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }

    onChangeCaptchaWithdrawal = (value) => {
      this.setState({captchaValueWithdrawal:value});
      //console.log("Captcha value Withdrawal:", value);
    }

    onChangeCaptchaMT = (value) => {
      this.setState({captchaValueMT:value});
      //console.log("Captcha value MT:", value);
    }

    doNSetting = async(e) => {
      if(!this.state.default_leg){
        this.props.enqueueSnackbar("Default leg is required!",{ variant: 'error' });
        this.setState({default_legError:true});
        return false;
      }
  
      try {
        this.setState({loadingNSetting:true, loading:true});
        //const data = await networkSettingApi('admin',this.state.default_leg);
        const data = await networkSettingApi(window.tronWeb.defaultAddress.base58,this.state.default_leg);
        //console.log('doNSetting',data);
        if(data.status){
          this.setState({transaction_hash:'', loadingNSetting:false, loading:false});
          this.props.enqueueSnackbar(data.message,{ variant: 'success' })
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          if(data.hasOwnProperty('message')){
            defaultError = data.message;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loadingNSetting:false, loading:false});
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loadingNSetting:false, loading:false});
        let defaultError = 'Some error occurred!';        
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }

    getTransaction = async(hash)=>{
      await Utils.setTronWeb(window.tronWeb);
  
      return new Promise( (resolve, reject) => {
        const result = {status:false, data:[]}
        let count = 0;
        let interval = setInterval(async()=>{
          if(count>15){
            reject(false);
            clearInterval(interval);
          }
          let result = await window.tronWeb.trx.getTransaction(hash);
          if(result.hasOwnProperty('ret') && result.ret[0].contractRet == 'SUCCESS'){
            resolve(true);
            clearInterval(interval);
          }
          count++;
        },1000);
  
      })
      
    }
  
    updateAddress = async(e) => {
      let validateForm = true;
      let inputValidAddress = this.state.inputValidAddress;
  
      if(!inputValidAddress){
        this.props.enqueueSnackbar("Address is required!",{ variant: 'error' })
  
        this.setState({sponsor_addressError:true});
        validateForm = false;
        return false;
      }    
  
      if(!validateForm){
          return false;
      }
  
      try {
        await SunMoon.setTronWeb(window.tronWeb);
  
        const balance = await window.tronWeb.trx.getBalance(window.tronWeb.defaultAddress.base58);
        console.log(validateForm, balance);
        if(balance >= 70000000){
            this.setState({loading:true});
            const res= await SunMoon.contract.updateAddress(this.state.inputValidAddress).send(
              {
                feeLimit:300000000,
                callValue:50000000
              }
            );
            console.log('After Submit...');
            console.log(res);
            // if(res){
            //   this.setState({loading:false,editAddress:false});
            //   this.props.enqueueSnackbar("Congratulation! Your Address updated successfully!",{ variant: 'success' })
            // }
  
            //await  HashPost(res);
            const data = await this.getTransaction(res);
            if(data){
              this.setState({loading:false,editAddress:false});
              this.props.enqueueSnackbar("Congratulation! Your Address updated successfully!",{ variant: 'success' })
            }else{
              this.setState({loading:false});
              this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' });
            }
  
        }else{
          this.props.enqueueSnackbar("Insufficient  Balance.! Minimum 70 TRX required to submit.",{ variant: 'error' })                
        }
  
      } catch (err) {
          console.log('------catch-------');
          console.log(err);
          this.setState({loading:false});
          let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';
          if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
            defaultError = window.tronWeb.toUtf8(err.output.contractResult[0]);
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }

    paginate = (
      totalItems,
      currentPage = 1,
      pageSize = 10,
      maxPages = 10
    ) => {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);
  
      // ensure current page isn't out of range
      if (currentPage < 1) {
          currentPage = 1;
      } else if (currentPage > totalPages) {
          currentPage = totalPages;
      }
  
      let startPage, endPage;
      if (totalPages <= maxPages) {
          // total pages less than max so show all pages
          startPage = 1;
          endPage = totalPages;
      } else {
          // total pages more than max so calculate start and end pages
          let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
          let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
          if (currentPage <= maxPagesBeforeCurrentPage) {
              // current page near the start
              startPage = 1;
              endPage = maxPages;
          } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
              // current page near the end
              startPage = totalPages - maxPages + 1;
              endPage = totalPages;
          } else {
              // current page somewhere in the middle
              startPage = currentPage - maxPagesBeforeCurrentPage;
              endPage = currentPage + maxPagesAfterCurrentPage;
          }
      }
  
      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  
      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  
      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
    }
  
    getPaginationData = async(url,page,type,current_page,last_page) => {
      if(!url && !page){
        return true;
      }
      this.setState({loading:true});
      this.tradeHistoryApi(url,page,type);
    }

    getPaginationDataPair = async(url,page,type,current_page,last_page,pair) => {
      if(!url && !page){
        return true;
      }
      this.setState({loading:true});
      this.tradeHistoryPairApi(url,page,type,pair);
    }

    getPaginationDataBot = async(url,page,type,current_page,last_page) => {
      if(!url && !page){
        return true;
      }
      this.setState({loading:true});
      this.activeBotApi(url,page,type);
    }

  
    activeBotApi = async(url=null,page=null,type=null)=>{
      const data = await activeBotApi(url,page,type);
      //console.log(data);
      
      if(data.status){
  
        let activeBots = data.data;
        let activeBotsPagination = this.paginate(activeBots.total,activeBots.current_page,activeBots.per_page,10);
        activeBots.pages = activeBotsPagination.pages;
        this.setState({
          activeBots:data.data,
          activeBots_setting:data.setting,
          loading:false
        });
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        this.setState({loading:false});
      }
    }

    tradeHistoryApi = async(url=null,page=null,type=null)=>{      
      const data = await tradeHistoryApi(url,page,type);
      //console.log(data);
      
      if(data.status){
  
        let tradeHistory = data.data;
        let tradeHistoryPagination = this.paginate(tradeHistory.total,tradeHistory.current_page,tradeHistory.per_page,10);
        tradeHistory.pages = tradeHistoryPagination.pages;
        
        this.setState({
          tradeHistory:data.data,
          loading:false        
        });
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        this.setState({loading:false});
      }
    }

    tradeHistoryPairApi = async(url=null,page=null,type=null,pair=null)=>{      
      const data = await tradeHistoryPairApi(url,page,type,pair);
      
      if(data.status){
  
        let tradeHistory = data.data;
        let tradeHistoryPagination = this.paginate(tradeHistory.total,tradeHistory.current_page,tradeHistory.per_page,10);
        tradeHistory.pages = tradeHistoryPagination.pages;
        
        this.setState({
          tradeHistoryPair:data.data,
          loading:false        
        });
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        this.setState({loading:false});
      }
    }

    closeModal = () =>{
      this.setState({modalIsOpen:false});
    }

    openModal = () =>{
      this.setState({modalIsOpen:true});
    }

    closeModalSim = () =>{
      this.setState({modalIsOpenSim:false});
    }

    openModalSim = () =>{
      this.setState({modalIsOpenSim:true});
    }

    render() {
      let this2 = this;
      return (
        <Frame withHeader={true} withFooter={true} isHome={true} user_address={this.state.userInfo.YourAddressfull}>
          <main>
            {this.state.loading ? (
              <div className="loader_body">
                <div className="loader">
                  <div className="line left"></div>
                  <div className="ball"></div>
                  <div className="line right"></div>
                </div>
              </div>
            ) : (null)}

            <div className="modal fade" id="modal_show">
              <div className="modal-dialog modal-md">
                <div className="modal-content" style={{backgroundColor: '#201e4f', color: '#fff'}}>
                  <div className="modal-header">
                    <h5 className="modal-title">Important Notice!</h5>
                    <button type="button" className="close" style={{color:'#fff'}} data-dismiss="modal">&times;</button>
                  </div>
                  <div className="modal-body text-center">
                    <p>Dear Users,</p>
                    <p>Due to increased congestion on TRON Network, there has been an increase in network fees.</p>
                    <p>We request you to please maintain a minimum balance 30 TRON to purchase a package.</p>
                    <p>Any inconvenience is regretted. </p>
                    <p>Regards <br /> Team Trondollar</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="modaToken_show" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false" data-backdrop="static" aria-modal="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content" style={{backgroundColor: 'rgb(5 1 34)', color: '#fff'}}>
                  <div className="modal-header">
                    <h5 className="modal-title" style={{width:'90%'}}>SunMoon Token</h5>
                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      
                      {this.state.editAddress ? (
                        
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-8 col-sm-8 col-lg-8">
                              <div className="form-group">
                                <input className="cus_input" type="text" placeholder="BNB Address"
                                  name="inputValidAddress"
                                  onChange={e => this.setState({inputValidAddress:e.target.value})}
                                  value={this.state.inputValidAddress || ''}
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-lg-4">
                              <div className="form-group">
                                <button className="grad_btn btn-block" disabled={this.state.loading} onClick={e => this.updateAddress(e)} >Update</button>
                              </div>
                            </div>
                          </div>
                          
                        
                        </div>
                      ):(
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">                          
                              <p className="mb_10" style={{marginTop:10}}>Your receiving address</p>
                              <p className="word-break theme_color address_text">
                                {this.state.getValidAddress}  
                                <button disabled={this.state.loading} onClick={e => this.setState({editAddress:true})} className="address_btn"><i className="fa fa-pencil-square-o"></i></button>
                              </p>
                            </div>
                          </div>
                        {/* <a className="view_details_btn" href="/token_distribution">View Details</a> */}
                        </div>
                      )}
                      
                      <p className="mb-0" style={{padding:10,fontStyle:'italic'}}>Note: Enter only BNB receiving address(Network: Binance Chain)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="intro-area intro-area-2" >
            <div className="bg-wrapper">
                <img src="image/bg2.jpg" alt="" />
            </div>
            <div className="intro-content">
                <div className="slider-content">
                    <div className="container">
                        <div className="row d-flex flex-wrap align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="slide-all-text">
                                    <div className="layer-2 wow fadeInUp" data-wow-delay="0.3s">
                                        <h1 className="title-2">Reimagine Crypto Investment <span className="color-text-bold"> with Digital </span> Transformation!  </h1>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="slide-images wow fadeInUp" data-wow-delay="0.3s">
                                    <img src="image/banner_img.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="about-area fix area-padding">
            <div className="container">
                <div className="row d-flex flex-wrap align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="about-images wow fadeInLeft" data-wow-delay="0.7s">
                                <img src="image/ab2.png" alt="" />
                                <div className="rotmate-image rotateme">
                                    <img src="image/circle.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="about-all">
                            <div className="about-inner">
                                <div className="single-about wow fadeInUp" data-wow-delay="0.3s">
                                    <span className="about-icon">01</span>
                                    <div className="support-text">
                                        <h4>High Scalability</h4>
                                        <p>The platform is highly scalable with an improved TPS in TRON. The system is entrusted with validating transactions and maintaining the system’s history to reach a consensus on its ledger.</p>
                                    </div>
                                </div>
                                <div className="single-about wow fadeInUp" data-wow-delay="0.5s">
                                    <span className="about-icon">02</span>
                                    <div className="support-text">
                                        <h4>Reliable Infrastructure</h4>
                                        <p>An extremely reliable network structure, user assets, intrinsic value, and a higher degree of decentralization collaboration come with an improved rewards distribution mechanism.</p>
                                    </div>
                                </div>
                                <div className="single-about wow fadeInUp mb-0" data-wow-delay="0.7s">
                                    <span className="about-icon">03</span>
                                    <div className="support-text">
                                        <h4>Tron Protocol</h4>
                                        <p>The Tron protocol is in place to distribute computing resources equally among TRX holders. A decentralized TVM (TRON Virtual Machine) utilizes an international network of public nodes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="brand-area bg-color-3">
            <div className="container">
                <div className="staking-inner wow fadeInUp mb-0" data-wow-delay="0.2s">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-5 col-md-5">
                            <div className="staking-content">
                                <div className="stack-time">
                                   <h3>Personal Details</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-7">
                            <div className="stack-name ">
                                <div className="stack-money">
                                    <div className="plan-money">
                                        <ul>
                                           <li>Total Deposit<span> = </span>{parseFloat(this.state.userDetails.user_investment).toFixed(4)} TRX</li>
                                           <li>Total Withdrawn<span> = </span>{parseFloat(this.state.userDetails.user_withdraw).toFixed(4)} TRX</li>
                                           <li className="mb-0">Referred By<span> = </span>
                                            <CopyToClipboard text={`${this.state.userDetails.userReferredByFull? this.state.userDetails.userReferredByFull : '-'}`}
                                              onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                                              <span style={{cursor:'pointer'}}>{this.state.userDetails.userReferredBy ? this.state.userDetails.userReferredBy : '-'}</span>
                                            </CopyToClipboard>
                                           </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="details_logo_p" src="image/logo.png" alt="" />
                </div>
            </div>
        </div>
            
        <div className="swap-area bg-color-3 fix area-padding">
            <div className="container mb-3">
                <div className="row d-flex flex-wrap align-items-center">
                   <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="swap-inner">
                            <div className="swap-token left-headline">
                                <div className="top-text-title">Tron Ecosystem</div>
                                <h2>Invest  <span className="color-text-bold">With </span> Us Now!</h2>
                                <p>TronDollar is the most promising platform for Tron investment, and we are connected to millions of investors across the globe. We closely track the global crypto ecosystem to provide you with a decentralized, safe, transparent, and universally accessible financial ecosystem developed only for crypto enthusiasts.</p>
                            </div>
                         </div>
                    </div> 
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="money-send">
                            <div className="money-send">
                                <div className="calculator-inner">
                                    <div className="stack-time">
                                        <ul className="trx_btn">
                                          {defaultAmountArr.map((amount,key) => {return(
                                            <li key={key}>
                                              <button className={this.state.amountTrx==amount?'active':'inactive'}
                                                onClick={e => this.setState({amountTrx:amount})}>
                                                {amount+' TRX'}
                                              </button>
                                            </li>
                                            )
                                          })}
                                        </ul>
                                    </div>
                                    <div className="form-group mt-4">
                                      {!this.state.contractInfo.ReferredBy ? (
                                        <input className="form-input" type="text" placeholder="Sponsor Address"
                                          name="sponsor_address"
                                          onChange={e => this.setState({sponsor_address:e.target.value})}
                                          value={this.state.sponsor_address || ''}
                                          error={this.state.sponsor_addressError}
                                        />
                                      ):(
                                        <input className="form-input" type="text" placeholder="Sponsor Address"
                                          readOnly
                                          value={this.state.contractInfo.ReferredBy || ''}
                                        />
                                      )}
                                    </div>
                                    <button className="cale-btn" disabled={this.state.loading} onClick={e => this.doSubmit(e)}>Join Now</button>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row d-flex flex-wrap align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="money-send">
                            <div className="money-send">
                                <div className="calculator-inner">
                                   <div className="form-group">
                                    <label>Available TRX</label>
                                        <input className="form-input" type="text" placeholder="Available TRX" readOnly value={parseFloat(this.state.userDetails.wallet_balance).toFixed(4) || ''} />
                                    </div>
                                   <div className="form-group mt-4">
                                    <label>Withdrawal TRX(Min:{this.state.min_withdraw} TRX)</label>
                                        <input className="form-input" type="text" placeholder={`Withdrawal TRX(Min:${this.state.min_withdraw} TRX)`}
                                        onChange={e => this.setState({withdrawlAmount:e.target.value})}
                                        value={this.state.withdrawlAmount || ''}
                                        />
                                    </div>
                                  
                                    <button className="cale-btn" disabled={this.state.loadingWithdrawl} onClick={e => this.doWithdrawl(e)}>Submit</button>
                                </div>
                            </div>
                       </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="swap-inner">
                            <div className="swap-token left-headline">
                                <div className="top-text-title">Decentralized Earning!</div>
                                <h2>Instant <span className="color-text-bold">Returns</span> Generated</h2>
                                <p>We have crafted a decentralized network with inherent advantages in terms of investments with good returns and the smart contract functionality that allows all the participants to invest and withdraw effortlessly. TronDollar is a Tron-based decentralized investment platform delivering tailored investment solutions that are effective, practical, safe, stable, and scalable. </p>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        
       
        <div className="information-area bg-color fix area-padding-2">
            <div className="container">
                <div className="row d-flex flex-wrap align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="price-box-inner">
                            <h5>Income Details 
                                {/* <span className="refresh_btn"><i className="ti-loop"></i></span> */}
                            </h5>
                            <div className="table-responsive">
                                <table className="table table-borderless text-white tableInfo">
                                <tbody>
                                    <tr>
                                      <td>Lifetime Income</td>
                                      <td>:</td>
                                      <td>{parseFloat(this.state.userDetails.lifetimeIncome).toFixed(4)} TRX</td>
                                    </tr>
                                    <tr>
                                      <td>Instant Income</td>
                                      <td>:</td>
                                      <td>{parseFloat(this.state.userDetails.instantIncome).toFixed(4)} TRX</td>
                                    </tr>
                                    <tr>
                                      <td>Team Income</td>
                                      <td>:</td>
                                      <td>{parseFloat(this.state.userDetails.teamIncome).toFixed(4)} TRX</td>
                                    </tr>
                                    <tr>
                                      <td>ROI Income</td>
                                      <td>:</td>
                                      <td>{parseFloat(this.state.userDetails.roiIncome).toFixed(4)} TRX</td>
                                    </tr>
                                    <tr>
                                      <td>Wallet Balance</td>
                                      <td>:</td>
                                      <td>{parseFloat(this.state.userDetails.wallet_balance).toFixed(4)} TRX</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                       <div className="info-images">
                           <img src="image/finix-coin.png" alt="" />
                       </div>
                    </div>
                </div>
                

                <h4 className="form-top mt-3">Active Bots</h4>
                <div className="table_box mt-3">
                  <div className="form-text">
                    <div className="form-inner table-inner">
                      <table>
                        <thead>
                          <tr> 	 	 	
                            {/* <th>Start Time</th> */}
                            <th>Exchange</th>
                            <th>Strategy</th>
                            {/* <th>Simulated Profit</th> */}
                            <th>Daily Profit Percentage</th>
                            {/* <th>Amount</th> */}
                            <th>Pair</th>
                            <th>Status</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.activeBots ? (
                            this.state.activeBots.data.length>0 ? (
                            this.state.activeBots.data.map(function(val, index){
                              let profit_key = val.pair+'_PROFIT';
                              let obj = this2.state.activeBots_setting.find(o => o.key === profit_key);
                              let profit = obj.value;
                                return (
                                <tr key={`active_bots${index}`}>
                                    {/* <td>{val.created_at}</td> */}
                                    <td>{val.exchange}</td>
                                    <td>{val.strategy}</td>
                                    {/* <td>{parseFloat(val.profit).toFixed(2)} %</td> */}
                                    <td>{parseFloat(profit).toFixed(4)} %</td>
                                    {/* <td>${parseFloat(val.amount).toFixed(2)}</td> */}
                                    <td>{val.pair}</td>                                        
                                    <td>
                                        {val.status === 1 && 'Active'}
                                        {val.status === 0 && 'Inactive'}
                                    </td>                                        
                                    {/* <td>                                          
                                      <button type="button" className="cmn--btn2"  onClick={()=>{
                                        this2.setState({selected_pair:val.pair,loading:true},()=>{                                              
                                          this2.tradeHistoryPairApi(null,null,null,this2.state.selected_pair);
                                          this2.openModal();
                                        })
                                      }}>History</button>
                                      <a type="button" href={val.simUrl} className="cmn--btn2">Bot</a>
                                    </td> */}

                                </tr>
                                )
                            })
                            ):(
                            <tr>
                                <td colSpan="4" className="text-center">
                                No Data Available!
                                </td>
                            </tr>
                            )
                          ) : (
                              <tr>
                              <td colSpan="4" className="text-center">
                                  No Data Available!
                                  </td>
                              </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* {this.state.activeBots ? (
                    <div className="text-center">
                      <ul className="table_pagination mb-0">
                          <li  style={{cursor:this.state.activeBots.prev_page_url?'pointer':'initial'}}>
                          <a onClick={()=>this.getPaginationDataBot(this.state.activeBots.prev_page_url,null,null,this.state.activeBots.current_page,this.state.activeBots.last_page)}><i className="fa fa-angle-double-left"></i></a>
                          </li>
                          {this.state.activeBots.pages.length>0 ? (
                          this.state.activeBots.pages.map(function(val, index){
                              let className = "";
                              if(val == this2.state.activeBots.current_page){
                              className = "active";
                              }
                              return (
                              <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.activeBots.current_page?'pointer':'initial'}}>
                                  <a onClick={()=>this2.getPaginationDataBot(null,'page',val,null,null)} >{val}</a>
                              </li>
                              )
                          })
                          ):(null)}
                          <li style={{cursor:this.state.activeBots.next_page_url?'pointer':'initial'}}>
                          <a onClick={()=>this.getPaginationDataBot(this.state.activeBots.next_page_url,null,null,this.state.activeBots.current_page,this.state.activeBots.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                          </li>
                      </ul>
                    </div>
                  ):(null)} */}
                </div>

                <h4 className="form-top mt-3">Bot Trade History</h4>
                <div className="table_box mt-3">
                  <div className="form-text">
                    <div className="form-inner table-inner">
                      <table>
                        <thead>
                          <tr> 	 	 	
                            {/* <th>Currency</th>
                            <th>Asset</th> */}
                            <th>Pair</th>
                            <th>Type</th>
                            {/* <th>Order Type</th> */}
                            <th>Price</th>
                            <th>Size</th>
                            <th>Total Price</th>
                            <th>Fee</th>
                            <th>Slippage</th>
                            <th>Completion Time</th>
                            {/* <th>Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.tradeHistory ? (
                              this.state.tradeHistory.data.length>0 ? (
                                  this.state.tradeHistory.data.map(function(val, index){                                                          
                                  return (
                                  <tr key={`trade_history${index}`}>
                                      {/* <td>{val.currency}</td>
                                      <td>{val.asset}</td> */}
                                      <td>{val.asset}-{val.currency}</td>
                                      <td>{val.type}</td>
                                      {/* <td>{val.order_type}</td> */}
                                      <td>{parseFloat(val.price).toFixed(2)}</td>
                                      <td>{parseFloat(val.size).toFixed(2)}</td>
                                      <td>{parseFloat(val.total_price).toFixed(2)}</td>
                                      <td>{parseFloat(val.fee).toFixed(2)}</td>
                                      <td>{val.slippage}</td>
                                      <td>{val.completion_time}</td>
                                      {/* <td>
                                          {val.status === 0 && 'Active'}
                                          {val.status === 1 && 'Closed'}
                                      </td> */}
                                  </tr>
                                  )
                              })
                              ):(
                              <tr>
                                  <td colSpan="4" className="text-center">
                                  No Data Available!
                                  </td>
                              </tr>
                              )
                          ) : (
                              <tr>
                              <td colSpan="4" className="text-center">
                                  No Data Available!
                                  </td>
                              </tr>
                          )}
                          </tbody>
                      </table>
                    </div>
                  </div>
                  {this.state.tradeHistory ? (
                    <div className="text-center">
                      <ul className="table_pagination mb-0">
                        <li  style={{cursor:this.state.tradeHistory.prev_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.tradeHistory.prev_page_url,null,null,this.state.tradeHistory.current_page,this.state.tradeHistory.last_page)}><i className="fa fa-angle-double-left"></i></a>
                        </li>
                        {this.state.tradeHistory.pages.length>0 ? (
                        this.state.tradeHistory.pages.map(function(val, index){
                            let className = "";
                            if(val == this2.state.tradeHistory.current_page){
                            className = "active";
                            }
                            return (
                            <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.tradeHistory.current_page?'pointer':'initial'}}>
                                <a onClick={()=>this2.getPaginationData(null,'page',val,null,null)} >{val}</a>
                            </li>
                            )
                        })
                        ):(null)}
                        <li style={{cursor:this.state.tradeHistory.next_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.tradeHistory.next_page_url,null,null,this.state.tradeHistory.current_page,this.state.tradeHistory.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                        </li>
                      </ul>
                    </div>
                    ):(null)}
                </div>
            </div>
        </div>

        <div className="staking-area bg-color-2 fix area-padding-2">
            <div className="container">
                <div className=" row align-items-center justify-content-center">
                    <div className="col-xl-8 col-lg-8 col-md-8">
                        <div className="section-bg-headline">
                           <div className="top-text-title">High ROI in Short Span!</div>
                            <h2>Copy the <span className="color-text-bold">referral </span>link here.</h2>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4">
                        <div className="section-bg-btn">
                          <CopyToClipboard text={`https://trondollar.com/${this.state.userInfo.YourAddressfull}`}
                            onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                            <button title="copy Link" className="head-btn coin-btn">Copy Link</button>
                          </CopyToClipboard>
                        </div>
                    </div> 
                    
				      </div>
                
                <h4 className="form-top">Network Setting</h4>
               <div className="calculator-inner">
                  <div className="row cus_row">
                    <div className="col-md-8 col-lg-8 col-sm-8">
                      <div className="form-group">
                        <select className="form-input" name="default_leg"
                          onChange={e => this.setState({default_leg:e.target.value})}
                          value={this.state.default_leg}
                          >
                          <option value="L">LEFT</option>
                          <option value="R">RIGHT</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4">
                      <button className="cale-btn" style={{marginTop:0}} disabled={this.state.loadingNSetting} onClick={e => this.doNSetting(e)}>Submit</button>
                    </div>
                  
                </div>
                </div>
               
            </div>
        </div>

        <div className="feature-area fix area-padding">
            <div className="container">
                <div className="row d-flex flex-wrap align-items-center">
                   <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="feature-inner">
                            <div className="left-headline">
                                <div className="top-text-title">Tron Wallet</div>
                                <h2>Enjoy Peace of <span className="color-text-bold">Mind with</span> Tron Wallet! </h2>
                                <p>A Tron wallet helps you protect your private key. With Tron Wallet, you are in total control of your funds. Receive, send, store, and exchange your cryptocurrency effortlessly from any corner of the planet. The multi-signature feature allows managing different sets of assets with various use cases and is a new way of storing crypto.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="feature-content">
                            <div className="feature-images">
                                <img src="image/f2.png" alt="" className="first-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          </main>
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    },
  }
  
  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;