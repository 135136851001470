import React, { Component } from 'react';
import Frame from "../../components/frame";
import TronWeb from 'tronweb';
import { utils as Utils, SunMoon } from '../../utils';
import {withRouter,Link} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {dataDetailsApi} from '../../utils';
const source = {
  'BINARY':'Lifetime Business Reward',
  'INSTANT':'Instant Business Reward',
  'TEAM':'Team Business Reward',
  'ROI':'ROI Business Reward'  
};

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      isConnected: false, 
      tronWeb: {
        installed: false,
        loggedIn: false
      },
      userInfo:{
        YourAddress: null,
        YourAddressfull:null
      },
      userDetails:{
        leftTeam:0,
        rightTeam:0,
        walletBalance: 0,
        totalPackage: 0,
        lifetimeIncome: 0,
        instantIncome: 0,
        teamIncome: 0,
        roiIncome: 0,
      },
      subscriptionList:null,
      withdrawList:null,
      incomeList:null,
      binaryPayoutLog:null,
    }
  }

  async componentDidMount(){
    await new Promise(resolve => {
    const tronWebState = {
        installed: !!window.tronWeb,
        loggedIn: window.tronWeb && window.tronWeb.ready
    };

    if(tronWebState.installed) {
        this.setState({
            tronWeb:
            tronWebState
        });

        return resolve();
    }

    let tries = 0;

    const timer = setInterval(() => {
        if(tries >= 10) {
            const TRONGRID_API = 'https://api.shasta.trongrid.io';

            window.tronWeb = new TronWeb(
                TRONGRID_API,
                TRONGRID_API,
                TRONGRID_API
            );

            this.setState({
                tronWeb: {
                    installed: false,
                    loggedIn: false
                }
            });

            clearInterval(timer);
            return resolve();
        }

        tronWebState.installed = !!window.tronWeb;
        tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;

        if(!tronWebState.installed)
            return tries++;

        this.setState({
            tronWeb: tronWebState
        });

        resolve();
    }, 100);
    });

    if(!this.state.tronWeb.loggedIn) {
        window.tronWeb.on('addressChanged', () => {
            if(this.state.tronWeb.loggedIn)
                return;

            this.setState({
                tronWeb: {
                    installed: true,
                    loggedIn: true
                }
            });
        });
    }
    
    this.fetchData();
    this.dataDetailsApi();

    window.tronWeb.on('addressChanged', () => {
        if(this.state.tronWeb.loggedIn){
          this.fetchData();
          this.dataDetailsApi();
        }
        
    });
  }

  fetchData= async()=>{
    await Utils.setTronWeb(window.tronWeb);
    
    this.setState(({userInfo})=>
      ({userInfo : {...userInfo, 
        YourAddress: window.tronWeb.defaultAddress.base58.slice(0, 10) + '.....' + window.tronWeb.defaultAddress.base58.slice(window.tronWeb.defaultAddress.base58.length-5, window.tronWeb.defaultAddress.base58.length),
        YourAddressfull:window.tronWeb.defaultAddress.base58,
      }})
    );
  }

  paginate = (
    totalItems,
    currentPage = 1,
    pageSize = 10,
    maxPages = 10
  ) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
  }

  getPaginationData = async(url,page,type,current_page,last_page) => {
    if(!url && !page){
      return true;
    }
    this.setState({loading:true});
    this.dataDetailsApi(url,page,type);
  }

  refreshApiData = async() => {
    this.setState({loading:true},()=>{
      this.dataDetailsApi();
    });
  }

  dataDetailsApi = async(url=null,page=null,type=null)=>{
    if(!window.tronWeb.defaultAddress.base58){
      return false;
    }
    const data = await dataDetailsApi(window.tronWeb.defaultAddress.base58,url,page,type);
    //const data = await dataDetailsApi('TTgH6SS8n1EBmH9AiT6cu8d1QvENhF7GXQ',url,page,type);
    // console.log('dataDetailsApi',data);
    if(data.status){
      this.setState(({userDetails})=>
        ({userDetails : {...userDetails,
          leftTeam:data.data.leftTeam,
          rightTeam:data.data.rightTeam,
          walletBalance: data.data.walletBalance,
          totalPackage: data.data.totalPackage,
          lifetimeIncome: data.data.lifetimeIncome,
          instantIncome: data.data.instantIncome,
          teamIncome: data.data.teamIncome,
          roiIncome: data.data.roiIncome,
        }})
      );

      let subscriptionList = data.data.subscriptionList;
      let subscriptionListPagination = this.paginate(subscriptionList.total,subscriptionList.current_page,subscriptionList.per_page,10);
      subscriptionList.pages = subscriptionListPagination.pages;

      let withdrawList = data.data.withdrawList;
      let withdrawListPagination = this.paginate(withdrawList.total,withdrawList.current_page,withdrawList.per_page,10);
      withdrawList.pages = withdrawListPagination.pages;

      let incomeList = data.data.incomes;
      let incomeListPagination = this.paginate(incomeList.total,incomeList.current_page,incomeList.per_page,10);
      incomeList.pages = incomeListPagination.pages;

      let binaryPayoutLog = data.data.binaryPayoutLog;
      let binaryPayoutLogPagination = this.paginate(binaryPayoutLog.total,binaryPayoutLog.current_page,binaryPayoutLog.per_page,10);
      binaryPayoutLog.pages = binaryPayoutLogPagination.pages;      
      
      this.setState({
        subscriptionList:data.data.subscriptionList,
        withdrawList:data.data.withdrawList,
        incomeList:data.data.incomes,
        binaryPayoutLog:data.data.binaryPayoutLog,        
        loading:false        
      });
    }else{
      let defaultError = "Some error occurred!";
      if(data.hasOwnProperty('error')){
        defaultError = data.error;
      }
      //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      this.setState({loading:false});
    }
  }
  render() {
    let this2 = this;
    return (
      <Frame withHeader={true} withFooter={true} withLeftpart={true} isHome={false} user_address={this.state.userInfo.YourAddressfull}>
        

        {this.state.loading ? (
          <div className="loader_body">
            <div className="loader">
              <div className="line left"></div>
              <div className="ball"></div>
              <div className="line right"></div>
            </div>
          </div>
        ) : (null)}

        <div className="bread-pd">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="bread-bg">
                            <div className="breadcrumb-title">
                                <h2>Details</h2>
                                <div className="bread-come">
                                    <nav aria-label="breadcrumb ">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-items"><a className="black-text" href="/">Home</a><i className="ti-angle-right" aria-hidden="true"></i></li>
                                            <li className="breadcrumb-items"><a className="black-text" href="/details">Details</a></li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <img className='breadcrumb_img' src="image/breadcrumb_img.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        <section className="pt-5 pb-5">
          <div className="container">
            
            <div className="md_container">
              <div className="row">
                <div className="col-md-4 col-sm-4 col-lg-4">
                  <div className="single-dash-head">
                      <div className="dashboard-amount d-flex flex-wrap align-items-center">
                          <div className="amount-content">
                              <span className="pro-name">Total Package <small>Amount</small></span>
                              <span className="pro-money">
                              {parseFloat(this.state.userDetails.totalPackage).toFixed(4)} TRX
                              </span>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4">
                  <div className="single-dash-head">
                      <div className="dashboard-amount d-flex flex-wrap align-items-center">
                          <div className="amount-content">
                              <span className="pro-name">Lifetime Income <small>Amount</small></span>
                              <span className="pro-money">
                                {parseFloat(this.state.userDetails.lifetimeIncome).toFixed(4)} TRX
                              </span>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4">
                  <div className="single-dash-head">
                      <div className="dashboard-amount d-flex flex-wrap align-items-center">
                          <div className="amount-content">
                              <span className="pro-name">Instant Income <small>Amount</small></span>
                              <span className="pro-money">
                                {parseFloat(this.state.userDetails.instantIncome).toFixed(4)} TRX
                              </span>
                          </div>
                        </div>
                  </div>
                </div>
                
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="single-dash-head">
                      <div className="dashboard-amount d-flex flex-wrap align-items-center">
                          <div className="amount-content">
                              <span className="pro-name">Team Income <small>Net Profit</small></span>
                              <span className="pro-money">
                                {parseFloat(this.state.userDetails.teamIncome).toFixed(4)} TRX
                              </span>
                          </div>
                        </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="single-dash-head">
                      <div className="dashboard-amount d-flex flex-wrap align-items-center">
                          <div className="amount-content">
                              <span className="pro-name">ROI Income <small>Net Profit</small></span>
                              <span className="pro-money">
                                {parseFloat(this.state.userDetails.roiIncome).toFixed(4)} TRX
                              </span>
                          </div>
                        </div>
                  </div>
                </div>
                
                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="single-dash-head">
                      <div className="dashboard-amount d-flex flex-wrap align-items-center">
                          <div className="amount-content">
                              <span className="pro-name">Left Team</span>
                              <span className="pro-money">
                                {this.state.userDetails.leftTeam}
                              </span>
                          </div>
                         
                      </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 col-lg-6">
                  <div className="single-dash-head">
                      <div className="dashboard-amount d-flex flex-wrap align-items-center">
                          <div className="amount-content">
                              <span className="pro-name">Right Team</span>
                              <span className="pro-money">
                                {this.state.userDetails.rightTeam}
                              </span>
                          </div>
                         
                      </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>

        <section className="pb-5">
          <div className="container">
            <div className="md_container">
              <h4 className='form-top'>Subscription History 
                  {/* <span className="float-right">
                    <a disabled={this.state.loading} onClick={this.refreshApiData}><img className="refresh_btn" src="img/refresh.png" alt="" /></a>
                  </span> */}
                  {/* <span className="float-right red_text">
                    <a title="Refresh">
                      <i disabled={this.state.loading} onClick={this.refreshApiData} className="fa fa-refresh" style={{fontSize:20, cursor:this.state.loading?'initial':'pointer'}}></i>
                    </a>
                  </span> */}
                </h4>
                <div className="table_box mb-5">
                  <div className="form-text">
                    <div className="form-inner table-inner">
                      <table>
                        <thead>
                          <tr>
                            <th>Total Amount </th>
                            <th>ROI percentage</th>
                            <th>ROI Income Limit</th>
                            <th>ROI Income Paid</th>
                            <th>ROI Income Date</th>
                            <th>Activation Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.subscriptionList ? (
                            this.state.subscriptionList.data.length>0 ? (
                              this.state.subscriptionList.data.map(function(val, index){                        
                                return (
                                  <tr key={`pah${index}`}>
                                    <td className="text-info">{parseFloat(val.total_amount).toFixed(4)} TRX</td>
                                    <td className="text-info">{parseFloat(val.roi_percentage).toFixed(2)}</td>
                                    <td className="text-info">{parseFloat(val.roi_income_limit).toFixed(4)} TRX</td>
                                    <td className="text-info">{parseFloat(val.roi_paid_income).toFixed(4)} TRX</td>
                                    <td>{val.roi_income_date}</td>
                                    <td>{val.created_at}</td>
                                  </tr>
                                )
                              })
                            ):(
                              <tr>
                                <td colSpan="10" className="text-center">No Data Available!</td>
                              </tr>
                            )
                          ) : (
                          <tr>
                            <td colSpan="10" className="text-center">No Data Available!</td>
                          </tr>
                          )}
                        </tbody>
                      </table>
                      {this.state.subscriptionList ? (
                        <div className="text-center">
                          <ul className="table_pagination mb-0">
                            <li  style={{cursor:this.state.subscriptionList.prev_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.subscriptionList.prev_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                            </li>
                            {this.state.subscriptionList.pages.length>0 ? (
                              this.state.subscriptionList.pages.map(function(val, index){
                                let className = "";
                                if(val == this2.state.subscriptionList.current_page){
                                  className = "active";
                                }
                                return (
                                  <li key={`pahp${index}`} className={className} style={{cursor:val != this2.state.subscriptionList.current_page?'pointer':'initial'}}>
                                    <a onClick={()=>this2.getPaginationData(null,'subs_page',val,null,null)} >{val}</a>
                                  </li>
                                )
                              })
                            ):(null)}
                            <li style={{cursor:this.state.subscriptionList.next_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.subscriptionList.next_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                            </li>
                          </ul>
                        </div>
                      ):(null)}
                    </div>
                  </div>
                </div>

                <h4 className='form-top'>Withdraw History</h4>              
                
                <div className="table_box  mb-5">
                  <div className="form-text">
                    <div className="form-inner table-inner">
                        <table>
                          <thead>
                            <tr>
                              <th># Transaction Id </th>
                              <th>Account</th>
                              <th>amount from</th>
                              <th>Amount To</th>
                              <th>remarks</th>
                              <th>status</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>                    
                            {this.state.withdrawList ? (
                              this.state.withdrawList.data.length>0 ? (
                                this.state.withdrawList.data.map(function(val, index){                        
                                  return (
                                    <tr key={`wl${index}`}>
                                      <td> {val.t_hash} </td>
                                      <td className="text-success"> {val.address}</td>
                                      <td className="text-success"> {parseFloat(val.amount_from).toFixed(4)} {val.coin_type}</td>                                                       
                                      <td className="text-success"> {parseFloat(val.amount_to).toFixed(4)} {val.coin_type}</td>                                                       
                                      <td> {val.remarks} </td>
                                      <td> {val.status} </td>
                                      <td> {val.created_at} </td>
                                    </tr>
                                  )
                                })
                              ):(
                                <tr>
                                  <td colSpan="7" className="text-center">No Data Available!</td>
                                </tr>
                              )
                            ) : (
                            <tr>
                              <td colSpan="7" className="text-center">No Data Available!</td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                        {this.state.withdrawList && this.state.withdrawList.data.length>0 ? (
                          <div className="text-center">
                            <ul className="table_pagination mb-0">
                              <li style={{cursor:this.state.withdrawList.prev_page_url?'pointer':'initial'}}>
                                <a onClick={()=>this.getPaginationData(this.state.withdrawList.prev_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                              </li>
                              {this.state.withdrawList.pages.length>0 ? (
                                this.state.withdrawList.pages.map(function(val, index){
                                  let className = "";
                                  if(val == this2.state.withdrawList.current_page){
                                    className = "active";
                                  }
                                  return (
                                    <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.withdrawList.current_page?'pointer':'initial'}}>
                                      <a onClick={()=>this2.getPaginationData(null,'withdraw_page',val,null,null)}>{val}</a>
                                    </li>
                                  )
                                })
                              ):(null)}
                              <li style={{cursor:this.state.withdrawList.next_page_url?'pointer':'initial'}}>
                                <a onClick={()=>this.getPaginationData(this.state.withdrawList.next_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i className="fa fa-angle-double-right"></i></a>
                              </li>
                            </ul>
                          </div>
                        ):(null)}
                    </div>       
                  </div>
                  <p className='text-center mt-3 mb-0'>
                    Available Balance: <b>{parseFloat(this.state.userDetails.walletBalance).toFixed(4)} TRX</b>
                  </p>
                </div>

                <h4 className='form-top'>Binary Payout Log</h4>
                <div className="table_box  mb-5">
                  <div className="form-text">
                    <div className="form-inner table-inner">
                      <table>
                        <thead>
                          <tr>
                            <th>left team count </th>
                            <th>right team count</th>
                            <th>left business</th>
                            <th>right business</th>
                            <th>total payout</th>
                            <th>commission</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.binaryPayoutLog ? (
                            this.state.binaryPayoutLog.data.length>0 ? (
                              this.state.binaryPayoutLog.data.map(function(val, index){                        
                                return (
                                  <tr key={`bll${index}`}>
                                    <td className="text-success"> {val.left_team_count}</td>
                                    <td className="text-success"> {val.right_team_count}</td>
                                    <td className="text-success"> {parseFloat(val.left_business).toFixed(4)} TRX</td>
                                    <td className="text-success"> {parseFloat(val.right_business).toFixed(4)} TRX</td>                            
                                    <td className="text-success"> {parseFloat(val.total_payout).toFixed(4)} TRX</td>
                                    <td className="text-success"> {parseFloat(val.commission).toFixed(4)} TRX</td>
                                  </tr>
                                )
                              })
                            ):(
                              <tr>
                                <td colSpan="7" className="text-center">No Data Available!</td>
                              </tr>
                            )
                          ) : (
                          <tr>
                            <td colSpan="7" className="text-center">No Data Available!</td>
                          </tr>
                          )}
                        </tbody>
                      </table>
                      {this.state.binaryPayoutLog && this.state.binaryPayoutLog.data.length>0 ? (
                        <div className="text-center">
                          <ul className="table_pagination mb-0">
                            <li style={{cursor:this.state.binaryPayoutLog.prev_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.prev_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)}><i className="fa fa-angle-double-left"></i></a>
                            </li>
                            {this.state.binaryPayoutLog.pages.length>0 ? (
                              this.state.binaryPayoutLog.pages.map(function(val, index){
                                let className = "";
                                if(val == this2.state.binaryPayoutLog.current_page){
                                  className = "active";
                                }
                                return (
                                  <li key={`bllp${index}`} className={className} style={{cursor:val != this2.state.binaryPayoutLog.current_page?'pointer':'initial'}}>
                                    <a onClick={()=>this2.getPaginationData(null,'binary_page',val,null,null)}>{val}</a>
                                  </li>
                                )
                              })
                            ):(null)}
                            <li style={{cursor:this.state.binaryPayoutLog.next_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.next_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)}><i className="fa fa-angle-double-right"></i></a>
                            </li>
                          </ul>
                        </div>
                      ):(null)}
                    </div>           
                  </div>
                </div>

                <h4 className='form-top'>Incomes</h4>
                <div className="table_box">
                  <div className="form-text">
                  <div className="form-inner table-inner">
                    <table>
                      <thead>
                        <tr>
                          <th> Amount </th>
                          <th> source </th>
                          <th> description </th>
                          <th> Date </th>
                        </tr>
                      </thead>
                      <tbody>                    
                        {this.state.incomeList ? (
                          this.state.incomeList.data.length>0 ? (
                            this.state.incomeList.data.map(function(val, index){                        
                              return (
                                <tr key={`si${index}`}>
                                  <td className="text-success"> {parseFloat(val.amount).toFixed(2)} {val.currency} </td>
                                  <td className="text-success"> {source[val.source]}</td>
                                  <td className="text-success"> {val.description}</td>
                                  <td className="text-success"> {val.created_at} </td>
                                </tr>
                              )
                            })
                          ):(
                            <tr>
                              <td colSpan="7" className="text-center">No Data Available!</td>
                            </tr>
                          )
                        ) : (
                        <tr>
                          <td colSpan="7" className="text-center">No Data Available!</td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                      {this.state.incomeList && this.state.incomeList.data.length>0 ? (
                        <div className="text-center">
                          <ul className="table_pagination mb-0">
                            <li style={{cursor:this.state.incomeList.prev_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.incomeList.prev_page_url,null,null,this.state.incomeList.current_page,this.state.incomeList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                            </li>
                            {this.state.incomeList.pages.length>0 ? (
                              this.state.incomeList.pages.map(function(val, index){
                                let className = "";
                                if(val == this2.state.incomeList.current_page){
                                  className = "active";
                                }
                                return (
                                  <li key={`sip${index}`} className={className} style={{cursor:val != this2.state.incomeList.current_page?'pointer':'initial'}}>
                                    <a onClick={()=>this2.getPaginationData(null,'income_page',val,null,null)}>{val}</a>
                                  </li>
                                )
                              })
                            ):(null)}
                            <li style={{cursor:this.state.incomeList.next_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.incomeList.next_page_url,null,null,this.state.incomeList.current_page,this.state.incomeList.last_page)}><i className="fa fa-angle-double-right"></i></a>
                            </li>
                          </ul>
                        </div>
                      ):(null)}
                    </div>        
                  </div>
                </div>

            </div>
          </div>
        </section>

      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  },
}

export default withRouter(withStyles(useStyles)(withSnackbar(Details)));

  //export default Details;