import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
          <header className="header-one">
        <div id="sticker" className="header-menu-area header-area">
            <div className="container">
                <div className="d-flex align-items-center">
                    <div className="mr-auto">
                        <div className="logo">
                            <a href="/"><img src="image/logo-text.png" alt="" /></a>
                        </div>
                    </div>
                    <div className="header_menu f-right">
                      <nav id="mobile-menu">
                        <ul className="main-menu">
                            <li><a href={`https://trondollar.com/app/en/binary-tree/${this.props.user_address}`}>Binary Tree</a></li>
                            <li>
                            {this.props.isHome ? (
                              <a href="/details">Details</a>
                            ):(
                              <a href="/">Home</a>
                            )}
                            </li>
                        </ul>
                      </nav> 
                    </div>
                  </div>
                  <div className="mobile-menu"></div>
            </div>
        </div>
    </header>


          
      );
    }
  }

  export default Header;